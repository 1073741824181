<template>
    <div class='editUser' :style='editUser'>
        <common-nav-bar title='“悦听悦心”健康会会员信息'></common-nav-bar>
        <van-cell-group class='bgNo'>
            <van-cell center title-class='userLeft' title='头像'>
                <template #right-icon>
                    <van-image round class='userPhoto' :src='user.wxHeadimgurl' />
                </template>
            </van-cell>
            <van-field
                v-model='name'
                label='姓名'
                placeholder='请输入用户姓名'
                input-align='right'
                required
            />
            <van-cell
                center
                title-class='userLeft'
                value-class='userRight'
                is-link
                title='性别'
                @click='showSexPop = true'
                :value='sex'
            />
            <van-cell
                center
                title-class='userLeft'
                value-class='userRight'
                is-link
                title='出生日期'
                :value='birthday'
                @click='showBirthdayPop = true'
            />
            <van-field
                v-model='idCard'
                label='身份证号'
                placeholder='请输入用户身份证号'
                input-align='right'
            />
            <van-field
                v-model='phone'
                type='tel'
                label='手机号'
                placeholder='请输入用户手机号'
                input-align='right'
                disabled
            />
            <van-cell
                center
                title-class='userLeft'
                value-class='userRight'
                is-link
                title='所在地区'
                :value='`${province} ${city} ${district}`'
                @click='showAreaPop = true'
            ></van-cell>
            <van-field
                v-model='address'
                label='详细地址'
                placeholder='请输入用户详细地址'
                input-align='right'
            />
            <van-cell class='textC bgNo' size='lager'>
                <template #title>
                    <van-button
                        class='newBook plr40 mt30'
                        color='#4AB8AB'
                        size='normal'
                        @click='submit'
                    >保存
                    </van-button>
                </template>
            </van-cell>
        </van-cell-group>
        <van-popup v-model='showSexPop' position='bottom'>
            <van-picker
                show-toolbar
                :columns='sexColumns'
                @confirm='pickSex'
                @cancel='showSexPop = false'
            />
        </van-popup>
        <van-popup v-model='showBirthdayPop' position='bottom'>
            <van-datetime-picker
                v-model='currentDate'
                type='date'
                :min-date='minDate'
                :max-date='maxDate'
                @confirm='pickBirthDate'
                @cancel='showBirthdayPop = false'
            />
        </van-popup>
        <van-popup v-model='showAreaPop' position='bottom'>
            <van-picker
                show-toolbar
                :columns='regionTree'
                value-key='name'
                @confirm='pickArea'
                @cancel='showAreaPop = false'
            />
        </van-popup>
    </div>
</template>

<script>
import { getUser, updateUserInfo } from '@/services/user';
import { toast, formatDate } from '@/util';
import { getRegionTree } from '@/services/region';
import CommonNavBar from '@/components/common-nav-bar';

export default {
    // name: "index"
    components: { CommonNavBar },
    data() {
        return {
            editUser: {
                background: '#F6F6F8',
                height: '100%'
            },
            showSexPop: false,
            sexColumns: ['男', '女'],
            showBirthdayPop: false,
            minDate: new Date('1950-01-01'),
            maxDate: new Date(),
            currentDate: new Date(),
            showAreaPop: false,
            regionTree: [],

            name: '',
            sex: '',
            birthday: '',
            idCard: '',
            phone: '',
            province: '',
            city: '',
            district: '',
            address: '',
            user: {}
        };
    },
    created() {
        this.getRegionTree();
        this.getUser();
    },
    methods: {
        async getRegionTree() {
            const { code, message, data } = await getRegionTree();
            if (!code) {
                toast(message);
            }
            this.regionTree = data;
        },

        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            this.user = data;
            await this.$store.dispatch('setUserInfo', data);
            this.initData();
        },

        initData() {
            this.name = this.user.name;
            this.sex = this.user.sex === 1 ? '男' : this.user.sex === 2 ? '女' : '';
            this.birthday = this.user.birthday
                ? formatDate(this.user.birthday, 'yyyy-MM-dd')
                : '';
            this.idCard = this.user.idCard;
            this.phone = this.user.phone;
            this.province = this.user.province;
            this.city = this.user.city;
            this.district = this.user.district;
            this.address = this.user.address;
        },

        pickSex(val) {
            this.sex = val;
            this.showSexPop = false;
        },

        pickBirthDate(val) {
            this.birthday = formatDate(val, 'yyyy-MM-dd');
            this.showBirthdayPop = false;
        },

        pickArea(val) {
            this.province = val[0];
            this.city = val[1];
            this.district = val[2];
            this.showAreaPop = false;
        },

        async submit() {
            const err = this.validate();
            if (err) {
                return toast(err);
            }
            let data = {
                name: this.name,
                sex: this.sex === '男' ? 1 : this.sex === '女' ? 2 : 0,
                birthday: this.birthday,
                idCard: this.idCard,
                province: this.province,
                city: this.city,
                district: this.district,
                address: this.address
            }
            const { code, message } = await updateUserInfo(data);
            if (!code) {
                return toast(message);
            }
            toast('保存成功');
            await this.$store.dispatch('setUserInfo', { ...this.$store.getters.getUserInfo, ...data });
            await this.$router.back();
        },

        validate() {
            if (!this.name) {
                return '请输入用户姓名';
            }
        }
    }
};
</script>

<style scoped>
.editUser .van-cell {
    margin-bottom: 5px;
}

.userPhoto {
    width: 35px;
    height: 35px;
}

.userLeft {
    font-size: 14px;
    color: #646566;
}

.userRight {
    font-size: 14px;
    color: #383838;
}

.newBook {
    height: 35px;
}
</style>
