<template>
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" @nav-bar-icon-color="'#000'" placeholder fixed/>
</template>

<script>
export default {
    name: 'common-nav-bar',
    props: { title: String },
    data() {
        return {};
    },
    methods: {
        onClickLeft() {
            this.$router.back();
        },
    },
};
</script>

<style scoped></style>
